document.addEventListener('DOMContentLoaded', function () {
    const rusteeImage = document.getElementById('rustee-image');
    const barkSound = document.getElementById('bark-sound');
    const barkCount = document.getElementById('bark-count');
    let userBarkCounter = 0;
    let globalBarkCounter = 0;
  
    // Function to fetch the global bark count from the server
    async function fetchGlobalBarkCount() {
      try {
        const response = await fetch('https://rusteebackendapi.vercel.app/api/');
        const data = await response.json();
        globalBarkCounter = data.globalBarkCount;
        updateGlobalBarkDisplay();
      } catch (error) {
        console.error('Error fetching global bark count:', error);
      }
    }
  
    // Function to update the global bark count on the page
    function updateGlobalBarkDisplay() {
      barkCount.textContent = `Barks: ${globalBarkCounter} (Your Barks: ${userBarkCounter})`;
    }
  
    // Function to handle the barking action
    async function bark() {
      // Play bark sound
      barkSound.play();
      
      // Add barking animation
      rusteeImage.classList.add('barking');
      
      // Remove barking animation after it's done
      setTimeout(() => {
        rusteeImage.classList.remove('barking');
      }, 1000);
  
      // Update user bark counter
      userBarkCounter++;
  
      // Send the user's bark count to the server to update the global count
      try {
        const response = await fetch('https://rusteebackendapi.vercel.app/api/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userBarks: 1 }) // Add 1 to the global count
        });
        const data = await response.json();
        
        // Update the global bark counter with the server response
        globalBarkCounter = data.globalBarkCount;
        updateGlobalBarkDisplay();
      } catch (error) {
        console.error('Error updating global bark count:', error);
      }
    }
  
    // Event listener for the Rustee image
    rusteeImage.addEventListener('click', bark);
  
    // Initial fetch of the global bark count
    fetchGlobalBarkCount();
  });
  